<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <div class="fullForm">
          <h2>Filter</h2>
          <v-form>
            <v-row>
              <v-col md="3">
                <div>
                  <v-text-field
                    v-model="name"
                    outlined
                    placeholder="Charging Station"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col md="1">
                <div>
                  <v-text-field
                    v-model="province"
                    outlined
                    placeholder="Province"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col md="2">
                <div>
                  <v-text-field
                    v-model="city"
                    outlined
                    placeholder="City"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col md="2">
                <div>
                  <v-text-field
                    v-model="address"
                    outlined
                    placeholder="Address"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col md="2">
                <div>
                  <v-text-field
                    v-model="telephone"
                    outlined
                    placeholder="Telephone"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col>
                <div>
                  <v-btn x-large v-if="!submitLoading" @click="filterAct"
                    >Filter</v-btn
                  >
                  <v-btn x-large loading v-if="submitLoading"></v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <v-col md="12">
          <div class="titleAction">
            <h1 class="systemTitle">Service Center</h1>
            <router-link to="/business/addServiceCenter"
              ><v-btn large>
                <v-icon class="mr-2"> mdi-plus </v-icon> Add Service
                Center</v-btn
              ></router-link
            >
          </div>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="greenBack">
                  <th class="text-left">Service Center</th>
                  <th class="text-left">Province</th>
                  <th class="text-left">City</th>
                  <th class="text-left">Address</th>
                  <th class="text-left">Telephone</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody v-if="!loading">
                <tr
                  v-for="(data, index) in serviceCenters"
                  :key="index + 'model'"
                  class="rowBox"
                >
                  <td>{{ data.name }}</td>
                  <td>{{ data.province }}</td>
                  <td>{{ data.city }}</td>
                  <td>{{ data.address }}</td>
                  <td>{{ data.telephone }}</td>
                  <td width="10">
                    <div class="d-flex">
                      <router-link
                        :to="`/business/addServiceCenter/${data._id}`"
                        ><v-icon> mdi-square-edit-outline </v-icon></router-link
                      >
                      <v-icon
                        class="ml-3"
                        v-if="!loadingDelete || id != data._id"
                        @click="removeServiceCenter(data._id)"
                      >
                        mdi-delete
                      </v-icon>
                      <v-progress-circular
                        class="ml-3"
                        indeterminate
                        v-if="loadingDelete && id == data._id"
                        size="20"
                        width="2"
                      ></v-progress-circular>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="loading">
                <tr>
                  <td>
                    Loading
                    <v-progress-circular
                      indeterminate
                      size="20"
                      width="2"
                      class="ml-2"
                    ></v-progress-circular>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="!loading">
                <tr>
                  <td colspan="7">
                    <ul class="pagination">
                      <li v-if="pagination.previous">
                        <v-icon @click="prev(pagination.previous)">
                          mdi-chevron-left
                        </v-icon>
                      </li>
                      <li>
                        <span v-if="pagination.previous && !pagination.next"
                          >{{
                            pagination.previous + 1 > 1
                              ? pagination.previous + 1 * limit
                              : 1
                          }}
                          -</span
                        >
                        <span v-if="pagination.previous && pagination.next"
                          >{{
                            pagination.previous + 1 > 1
                              ? pagination.previous + 1 * limit
                              : 1
                          }}
                          -</span
                        >
                        <span v-if="pagination.next && !pagination.previous"
                          >{{
                            pagination.next - 1 > 1
                              ? pagination.next - 1 * limit
                              : 1
                          }}
                          -</span
                        >
                        {{ pagination.total }} Items
                      </li>
                      <li v-if="pagination.next">
                        <v-icon @click="next(pagination.next)">
                          mdi-chevron-right
                        </v-icon>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ServiceCenter",
  data: () => ({
    serviceCenters: [],
    loading: true,
    loadingDelete: false,
    pagination: undefined,
    limit: 10,
    page: 1,
    id: undefined,
    name: undefined,
    province: undefined,
    city: undefined,
    address: undefined,
    telephone: undefined,
  }),
  methods: {
    ...mapActions(["getServiceCenterList", "deleteServiceCenter"]),
    async removeServiceCenter(id) {
      this.loadingDelete = true;
      this.id = id;
      await this.deleteServiceCenter(id);
      this.loadingDelete = false;
      this.serviceCenters = this.allServiceCenters.results;
      this.pagination = this.allServiceCenters.pagination;
    },
    async next(data) {
      this.loading = true;
      await this.getServiceCenterList({ limit: this.limit, page: data });
      this.serviceCenters = this.allServiceCenters.results;
      this.pagination = this.allServiceCenters.pagination;
      this.loading = false;
    },
    async prev(data) {
      this.loading = true;
      await this.getServiceCenterList({ limit: this.limit, page: data });
      this.serviceCenters = this.allServiceCenters.results;
      this.pagination = this.allServiceCenters.pagination;
      this.loading = false;
    },
    async filterAct() {
      this.submitLoading = true;
      let filter = {};
      if (this.name != undefined && this.name != '') {
        filter.name = this.name;
      }
      if (this.province != undefined && this.province != '') {
        filter.province = this.province;
      }
      if (this.city != undefined && this.city != '') {
        filter.city = this.city;
      }
      if (this.address != undefined && this.address != '') {
        filter.address = this.address;
      }
      if (this.telephone != undefined && this.telephone != '') {
        filter.telephone = this.telephone;
      }
      await this.getServiceCenterList({
        limit: this.limit,
        page: this.page,
        ...filter,
      });
      this.serviceCenters = this.allChargingStations.results;
      this.submitLoading = false;
    },
  },
  computed: {
    ...mapGetters(["allServiceCenters"]),
  },
  async created() {
    await this.getServiceCenterList({ limit: this.limit, page: this.page });
    this.serviceCenters = this.allServiceCenters.results;
    this.pagination = this.allServiceCenters.pagination;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.pagination {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .v-icon {
    font-size: 18px;
    height: 30px;
    width: 30px;
    display: flex;
  }
}
.titleAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  a {
    text-decoration: none;
  }
}
.rowBox {
  td {
    padding: 10px !important;
  }
}
a {
  text-decoration: none;
}
.fullForm {
  margin-top: 20px;
  width: 100%;
  height: 100px;
  padding: 0 15px;
  h2 {
    margin-bottom: 10px;
  }
}
</style>